@import '../vars';
@import '../utils/mixins';

.app-details {
  min-width: 940px;
  max-width: 1699px;
  display: flex;
  flex-direction: row;
  padding: 0 60px;

  @include onTablet {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    max-width: 780px;
    min-width: 680px;
    padding: 0;
  }


  @include onMobile {
    min-width: 280px;
    max-width: 640px;
  }


  &-position {
    display: flex;
    flex-direction: column;
    gap: 30px;
    @include onTablet {
      margin: auto;
      flex-direction: column-reverse;
      margin-bottom: 20px;
      padding: 0 20px;
    }
  }

  &-container {
    width: 100%;
    padding-top: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &-box {
    width: 167px;
    height: 167px;
    background-color: white;
    border-radius: 22px;
    padding: 10px;
    display: flex;
    align-items: center;

    @include onTablet {
      width: 60px;
      height: 60px;
    }

    @include onMobile {
      width: 48px;
      height: 48px;
    }

    &-icon {
      width: 100%;
    }
  }

  &-title {
    flex: 1; 
    background-color: $darkBlue;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    color: white;
    font-size: 30px;
    justify-content: center;
    padding: 50px 10px;

    @include onTablet {
      border-radius: 15px;
      flex-direction: row;
      padding: 10px 40px;
      justify-content: start;
    }

    @include onMobile {
      font-size: 20px;
    }
  }

  &-items {
    flex: 3;
    background-color: white;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 60px 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include onTablet {
      border-radius: 15px;
      justify-content: center;
    }

    @include onMobile {
      padding: 40px 30px;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &-warning {
    @include onTablet {
      display: none;
    }

    &-title {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: baseline;
    }

    &-icon {
      height: 18px;
      width: 20px;
    }
  }

  &-warn {
    display: none;
    @include onTablet {
      display: block;
      background-color: white;
      border-radius: 15px;
      padding: 40px 60px;
    }
  }

  &-subtitle {
    font-size: 26px;
    font-weight: bold;
  }

  &-button {
    height: 70px;
    width: 100%;
    border-radius: 15px;
    background-color: $darkBlue;
    color: white;
    border: none;
    font-size: 32px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    @include onMobile {
      border-radius: 22px;
      font-size: 14px;
      height: 40px;
      margin-top: 30px;
      > img {
        width: 18px;
        height: 18px;
      }
    }
  }

  &-info {
    @include onTablet {
      width: 100%;
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &-name {
      text-align: center;
    }
    
    &-qr {
      height: 130px;
      width: 130px;

      @include onMobile {
        display: none;
      }

      &-mobile {
        display: none;

        @include onMobile {
          display: flex;
          justify-content: center;
          width: 100%;

          > img {
            height: 130px;
            width: 130px;
          }
        }
      }
    }
    &-box {
      border-top: 1px solid;
      border-bottom: 1px solid;
      height: 200px;
      margin-top: 20px;
      margin-bottom: 40px;
      padding: 16px 10px;
      max-width: 400px;
      padding-right: 100px;

      @include onTablet {
        max-width: none;
        padding-right: 0;
      }

      @include onMobile {
        height: fit-content;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &-bold {
      color: black;
      font-weight: bold;
    }

  }

  &-download {
    width: 38px;
    height: 38px;
  }

  &-android {
    @include onMobile {
      display: none;
    }

    &-mobile {
      @include onMobile {
        width: 100%;
      }
    }
  }

  &-ios {
    @include onMobile {
      width: 100%;
    }
  }

  &-back {
    padding-left: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-size: 26px;
    cursor: pointer;
    margin-bottom: 10px;

    @include onTablet {
      padding: 0;
    }

    @include onMobile {
      font-size: 14px;
    }

    &-icon {
      width: 15px;
      height: 15px;
    }
  }

  &-store {
    outline: none;
    border: none;
    background-color: transparent;

    &-icon {
      width: 186px;
      height: 55px;
    }

    @include onMobile {
      display: flex;
      justify-content: center;
      width: 100%;
    }

  }
}

.app-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 80px;

  @include onTablet {
    justify-content: space-between;
  }

  @include onMobile {
    flex-direction: column;
    align-items: normal;
  }
}

.app-warning {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
}

.apocampus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  &-mobile {
    display: none;

    @include onMobile {
      display: flex;
    }
  }

  @include onTablet {
    justify-content: space-between;
  }

}

.spinner-container {
  height: 100%;
  margin-left: -1.5rem;
  margin-top: -1.5rem;
  width: 100%;
  z-index: 20;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  .spinner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 12rem;

    img {
      display: flex;
      width: 6rem;
      margin-top: -4rem;
    }

    .spinner-text {
      width: 100%;
      text-align: center;
      margin: 1rem auto 0;
      display: flex;
      justify-content: center;
      font-family: var(--font-body);
      font-size: 1rem;
      color: $darkBlue;
      line-height: 1.25rem;
    }
  }
}
