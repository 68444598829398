@import '../vars';
@import '../utils/mixins';

.app-item {
  width: 240px;
  height: 200px;
  background-color: $backgroundGray;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;  justify-content: space-between;

  @include onTablet {
    width: 200px;
    height: 200px;
  }

  &-button {
    width: 100%;
    height: 40px;
    outline: none;
    background-color: $darkBlue;
    border: none;
    border-radius: 22px;
    color: white;
    font-size: 14px;
    cursor: pointer;
  }

  &-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
  }

  &-image {
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      max-height: 100px;
      max-width: 200px;
    }
  }
}