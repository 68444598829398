@import '../vars';
@import '../utils/mixins';

.category-list {
  min-width: 940px;
  display: flex;
  flex-direction: row;
  padding: 0 60px;

  @include onTablet {
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
    min-width: 600px;
    max-width: 840px;
  }


  @include onMobile {
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
    min-width: 280px;
    max-width: 640px;
  }

  &-title {
    flex: 1; 
    background-color: $darkBlue;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    color: white;
    font-size: 30px;
    justify-content: center;

    @include onTablet {
      border-radius: 15px;
      flex-direction: row;
      padding: 20px 40px;
      justify-content: start;

      > img {
        height: 36px;
        width: 36px;
      }

      > span {
        font-size: 20px;
      }
    }
  }

  &-items {
    flex: 3;
    background-color: white;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;

    @include onTablet {
      border-radius: 15px;
      padding: 50px 40px;
    }

    @include onMobile {
      justify-content: center;
      border-radius: 15px;
      padding: 50px 20px;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin: auto;
  }
}