@import '../vars';
@import '../utils/mixins';

.nav {
  width: 100%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: $primaryBlue;
  position: sticky; 
  top: 0;

  &-header {
    position: sticky;
    z-index: 10;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  &-container {
    display: flex;
    justify-content: space-between;
    transition: all 150ms ease-in-out;
    height: 6rem;
    padding: 1rem;
  }

  &-logo {
    height: 2rem;
    width: auto;
    padding: 0 30px; 
  
    &-container {
      display: flex; 
      height: 100px;
      align-items: center;

      @include onMobile {
        justify-content: center;
      }
    }
  }
}