@mixin onTablet {
  @media (max-width: 1024px) {
   @content;
  }
}

@mixin onMobile {
  @media (max-width: 768px) {
   @content;
  }
}