@import '../vars';
@import '../utils/mixins';

.apps-list {
  display: flex;
  height: 100%;
  padding: 80px 0;
  background-color: $backgroundGray;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 60px;

  @include onTablet {
    padding: 40px 0;
  }
}