@import '../vars';
@import '../utils/mixins';
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 50; 
  flex-wrap: wrap;

  &-content {
    flex: 1;
    background-color: $backgroundGray;
    overflow: auto;
  }

  &-footer {
    z-index: 10;
    background-color: #FFFFFF; 
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 0 20px;
    align-items: center;

    @include onMobile {
      justify-content: center;
    }

    &-item {
      color: black;
      display: block;

      &-link {
        text-decoration: underline;
      }
    }

    &-links {
      display: flex;
      flex-direction: row;
    }
  }

  &-divider {
    color: black;
    margin: 0 10px;
  }
}

.mark {
  @include onMobile {
    display: none;
  }
}
